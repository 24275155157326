<template>
  <v-row>
    <v-col cols="6" offset="3" class="d-sm-none pb-0">
      <img
        src="../../assets/logoFichasAzul.png"
        class="img-fichas-azul-deposit"
      />
    </v-col>
    <v-col cols="12" md="9" style="margin: auto" class="pb-1">
      <p class="text-withdraw mb-4">
        Estamos quase lá! Agora selecione o
        <span class="extra-bold">valor desejado </span> para
        <span class="extra-bold">saque!</span>
      </p>
    </v-col>
    <v-col cols="6">
      <v-row> </v-row>
    </v-col>
    <div class="box-chips mt-2">
      <div
        v-for="option in options"
        :key="option.id"
        :class="{
          'option-selected': selectedOptionId === option.id,
        }"
        @click="selectOption(option)"
        class="chip-withdraw mb-7"
      >
        <p v-if="option.id == 1" class="indicated-withdraw">VALOR MÍNIMO</p>
        <img
          src="../../assets/ficha.png"
          :alt="option.alt"
          class="img-ficha-withdraw"
        />
        <p class="text-value">{{ option.text }}</p>
      </div>
    </div>

    <v-col
      cols="12"
      sm="10"
      md="8"
      class="text-center mt-0"
      style="margin: auto"
    >
      <v-text-field
        v-model="formDataWithdrawChips.valueChips"
        prepend-inner-icon="mdi-currency-brl"
        label="Digite aqui outro valor"
        variant="outlined"
        color="#0e0d5e"
        rounded
        @input="deselectOption"
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row class="mt-0">
    <v-col cols="6" offset="3">
      <button
        v-if="!loading"
        @click="nextWithdrawStep()"
        class="button-withdraw-init"
      >
        <div class="text-wrapper-withdraw">CONTINUAR</div>
      </button>
      <v-progress-circular
        v-else-if="loading"
        indeterminate
        color="#0D3A50"
        :size="50"
        class="loading-spinner"
      />
    </v-col>
    <v-col cols="3" class="d-none d-sm-block">
      <img src="../../assets/logoFichasAzul.png" class="img-fichas-azul" />
    </v-col>
  </v-row>
  <!-- <Spinner class="loading-spinner" v-else-if="!loadingStep" /> -->
</template>

<script>
import { mapState, mapActions } from "vuex";
import rules from "../../utils/rules";
import { showNotificationError } from "@/services/notifications/index.js";
// import Spinner from "@/components/SpinnerLoading.vue";

export default {
  data() {
    return {
      rules: rules,
      selectedOptionId: 1,
      options: [
        {
          id: 1,
          alt: "Opção 1",
          value: "30",
          text: "R$ 30,00",
        },
        {
          id: 2,
          alt: "Opção 2",
          value: "50",
          text: "R$ 50,00",
        },
        {
          id: 3,
          alt: "Opção 3",
          value: "100",
          text: "R$ 100,00",
        },
      ],
    };
  },
  computed: {
    ...mapState("withdraw", ["formDataWithdrawChips", "loading"]),
    ...mapState("login", ["userLogged"]),
  },
  components: {
    // Spinner,
  },
  methods: {
    ...mapActions("withdraw", ["getKeysPix"]),

    nextWithdrawStep() {
      if (this.formDataWithdrawChips.valueChips < 30) {
        showNotificationError("Valor menor que o mínimo permitido");
        return;
      }
      this.getKeysPix();
    },
    selectOption(option) {
      this.selectedOptionId = option.id;
      this.formDataWithdrawChips.valueChips = option.value;
    },
    deselectOption() {
      this.selectedOptionId = null;
    },
  },
};
</script>
<style src="../../styles/WithdrawChips.css"></style>