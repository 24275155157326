<template>
  <v-container class="form-container-withdraw mb-16">
    <v-card class="form-card-withdraw px-4">
      <v-card-title
        class="form-header-withdraw pb-0"
      >
        <v-btn icon @click="goBack" class="back-button">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn icon @click="closeModal" class="close-button">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="form-content-withdraw pr-0 pl-0 pt-0">
        <StepsWithdrawComponent :step="currentStep" />
      </v-card-text>
    </v-card>
  </v-container>
</template>




<script>
import StepsWithdrawComponent from "@/components/withdraw/StepsWithdrawComponent.vue";
// import StepperComponent from "@/components/StepperComponent.vue";

import { mapMutations, mapState } from "vuex";

export default {
  props: {},
  components: {
    StepsWithdrawComponent,
    // StepperComponent,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("withdraw", ["showSteps", "currentStep"]),
  },
  methods: {
    ...mapMutations("withdraw", [
      "SET_PREVIOUS_CURRENT_STEP",
      "SET_CURRENT_STEP",
    ]),

    goBack() {
      if (this.currentStep == 1) {
        this.closeModal();
      } else if (this.currentStep == 4) {
        this.SET_CURRENT_STEP(this.currentStep - 1);
      } else {
        this.SET_PREVIOUS_CURRENT_STEP(this.currentStep);
      }
    },
    closeModal() {
      this.$router.push("/menu");
    },
  },
};
</script>

<style src="../../styles/Withdraw.css"></style>

