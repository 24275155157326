<template>
  <v-container class="form-container-purchase mb-16">
    <v-card class="form-card-purchase px-6">
      <v-card-title class="form-header-purchase pb-1">
        <v-btn
          v-if="currentStep == 5 || userLogged"
          icon
          @click="goBack"
          class="back-button"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <StepperComponent v-if="showSteps" :step="currentStep" />
        <p
          v-else-if="userLogged && currentStep == 4"
          style="font-size: 30px; font-weight: 900; color: #0d3a50"
          class="mb-0"
        >
          COMPRAR FICHAS
        </p>

        <v-spacer></v-spacer>
        <v-btn
          v-if="currentStep == 5 || userLogged"
          icon
          @click="closeModal"
          class="close-button"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="form-content-purchase">
        <StepsPurchaseComponent :step="currentStep" />
      </v-card-text>
    </v-card>
  </v-container>
</template>




<script>
import StepsPurchaseComponent from "@/components/purchase/StepsPurchaseComponent.vue";
import StepperComponent from "@/components/StepperComponent.vue";

import { mapMutations, mapState } from "vuex";

export default {
  props: {},
  components: {
    StepsPurchaseComponent,
    StepperComponent,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("purchase", ["showSteps", "currentStep"]),
    ...mapState("login", ["userLogged"]),
  },
  methods: {
    ...mapMutations("purchase", [
      "SET_PREVIOUS_CURRENT_STEP",
      "SET_CURRENT_STEP",
    ]),

    goBack() {
      if (this.currentStep > 4) {
        this.SET_PREVIOUS_CURRENT_STEP(this.currentStep - 1);
      } else if (this.currentStep == 4) {
        this.closeModal();
      }
    },
    closeModal() {
      this.$router.push("/menu");
      this.SET_CURRENT_STEP(4);
    },
  },
};
</script>

<style src="../../styles/Purchase.css"></style>

